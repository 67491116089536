<template>
  <div>
    <el-form
      v-loading="loading"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        id="id"
        label="门派ID"
        prop="id"
        :rules="[
          {
            required: true,
            message: '请输入门派ID',
            trigger: ['blur', 'change'],
          },
          {
            min: 6,
            max: 6,
            message: '长度6个字符',
            trigger: ['blur', 'change'],
          },
          { validator: idValidator, trigger: ['blur', 'change'] },
        ]"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="id"
            v-model="dataForm.id"
            maxlength="6"
            placeholder="请输入门派ID"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="name"
        label="门派名称"
        prop="name"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="name"
            v-model="dataForm.name"
            maxlength="10"
            placeholder="请输入门派名称"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="门派头像" prop="avatar" id="avatar">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeTopImageUpload"
          action="#"
          drag
          :http-request="httpUploadRequest"
        >
          <img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar" />
          <div v-else>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
        id="declaration"
        label="门派宣言"
        prop="declaration"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <el-input
            ref="declaration"
            type="textarea"
            v-model="dataForm.declaration"
            maxlength="200"
            placeholder="请输入门派宣言"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="city"
        label="城市"
        prop="city"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="city"
            v-model="dataForm.city"
            maxlength="10"
            placeholder="请输入城市"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="门派介绍" prop="desc" id="quillEditorQiniu">
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.desc"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="合作桌游吧名称">
        <el-col :span="6">
          <el-input
            ref="city"
            v-model="dataForm.board_game_bar.name"
            placeholder="请输入合作桌游吧名称"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="合作桌游吧所在地区">
        <el-cascader
          v-model="valueCascader"
          class="elcascader"
          :options="options"
          clearable
          placeholder="请输入合作桌游吧所在地区"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="合作桌游吧详细地址">
        <el-col :span="6">
          <el-input
            ref="city"
            v-model="dataForm.board_game_bar.address"
            placeholder="请输入合作桌游吧详细地址"
              maxlength="30"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 桌游吧介绍 -->
      <el-form-item
        v-if="dataForm.board_game_bar.has"
        label="桌游吧介绍"
        id="quillEditorQiniu1"
        style="height: 520px"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest1"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.board_game_bar.desc"
          ref="customQuillEditor1"
          :options="editorOption1"
        >
        </quill-editor>
      </el-form-item>
      <!-- 桌游吧小程序链接 -->
      <!-- <el-form-item label="桌游吧小程序链接" style="margin-bottom: 35px">
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.board_game_bar.link"
            placeholder="请输入桌游吧小程序链接"
          ></el-input>
        </el-col>
      </el-form-item> -->
      <!-- 门派裁判游戏编号 -->
      <el-form-item label="门派裁判游戏编号" style="margin-bottom: 35px">
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.board_game_bar.referee_id"
            placeholder="请输入门派裁判游戏编号"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 门派记者游戏编号 -->
      <el-form-item label="门派记者游戏编号" style="margin-bottom: 35px">
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.board_game_bar.journalist_id"
            placeholder="请输入门派记者游戏编号"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 主播游戏编号 -->
      <el-form-item label="主播游戏编号" style="margin-bottom: 35px">
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.board_game_bar.anchor_id"
            placeholder="请输入主播游戏编号"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 主播所在平台 -->
      <el-form-item label="门派主播所在平台" style="margin-bottom: 35px">
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.board_game_bar.anchor_platform"
            placeholder="请输入门派主播所在平台"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 主播是否签约 -->
      <el-form-item label="主播是否签约" style="margin-bottom: 35px">
        <el-checkbox
          v-model="dataForm.board_game_bar.anchor_sign"
        ></el-checkbox>
      </el-form-item>
      <el-form-item
        v-if="
          dataForm.board_game_bar &&
            dataForm.board_game_bar.addr &&
            dataForm.board_game_bar.addr
        "
        label="桌游吧地图"
        prop="board_game_bar.gis"
        id="board_game_bar_gis"
      >
        <div style="display: flex; flex-direction: row">
          <el-input
            ref="board_game_bar_gis"
            v-model="dataForm.board_game_bar.gis.addr"
            readonly
            style="width: 500px"
          ></el-input>
          <el-button type="default" @click="clearGis">清除</el-button>
        </div>
        <mapPick
          :gis="dataForm.board_game_bar.gis"
          @onSelect="onMapSelect"
        ></mapPick>
      </el-form-item>
      <el-form-item label="成员" prop="members">
        <el-button type="primary" @click="handleAddMember">添加</el-button>
        <el-table
          :data="dataForm.members"
          style="margin-top:20px"
          :header-cell-style="{ background: '#409EFF' }"
          border
        >
          <el-table-column prop="name" label="名称" width="200">
          </el-table-column>
          <el-table-column label="头像" width="200" align="center">
            <template slot-scope="scope">
              <img
                style="border-radius: 20px; width: 40px; height: 40px"
                :src="scope.row.avatar"
              />
            </template>
          </el-table-column>
          <el-table-column prop="uid" label="UID" width="150" align="center">
          </el-table-column>
          <el-table-column prop="job" label="职务" width="120" align="center">
          </el-table-column>
          <el-table-column prop="desc" label="简介"> </el-table-column>
          <el-table-column label="操作" fixed="right" width="150">
            <template slot-scope="scope">
              <el-button @click="handleEditMember(scope.row)">编辑</el-button>
              <popconfirmButton
                content="是否确认删除？"
                text="删除"
                @onConfirm="handleDeleteMember(scope.row)"
                style="margin-left: 10px"
              ></popconfirmButton>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item style="margin-top: 50px; text-align: center">
        <perButton
          type="primary"
          perm="armory:sect:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button type="default" @click="previewVisible = true" size="large"
          >预览</el-button
        >
      </el-form-item>
    </el-form>

    <el-dialog
      :title="membersAction == 0 ? '编辑' : '新增'"
      :visible.sync="membersVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        ref="membersForm"
        :model="membersParams"
        label-width="80px"
        :rules="membersRules"
      >
        <el-form-item label="成员名称" prop="name">
          <el-input
            v-model="membersParams.name"
            placeholder="请输入成员名称"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="成员头像" prop="avatar">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="#"
            :before-upload="beforeMemberImageUpload"
            drag
            :http-request="httpUploadMemberRequest"
          >
            <img
              v-if="membersParams.avatar"
              :src="membersParams.avatar"
              class="avatar"
            />
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
            </div>
          </el-upload>
          <!--          <p>上传图片尺寸300*300</p>-->
        </el-form-item>
        <el-form-item label="成员UID" prop="uid">
          <el-input
            v-model="membersParams.uid"
            placeholder="请输入成员UID"
            maxlength="8"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="成员职位" prop="job">
          <el-input
            v-model="membersParams.job"
            placeholder="请输入成员职位"
            maxlength="4"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="成员介绍" prop="desc">
          <el-input
            v-model="membersParams.desc"
            :rows="3"
            type="textarea"
            maxlength="60"
            placeholder="请输入成员介绍"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changeMember">确定</el-button>
          <el-button @click="cancelDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <preview
      v-if="previewVisible"
      :info="dataForm"
      @closed="onPreviewClosed"
    ></preview>
  </div>
</template>

<script>
import { cityList } from './city'
import { uploadOssUrl } from '@/utils/uploadUrl'
import perButton from '@/components/perm/perButton'
import { randomStr } from '@/utils/randomStr'
import preview from './preview'
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ indent: '-1' }, { indent: '+1' }],
  [{ size: ['20rpx', '24rpx', '26rpx', '32rpx', '48rpx', 'custom-size'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        '#000000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',
        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',
        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',
        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466',
        'custom-color',
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ['image'],
  ['clean'], // remove formatting button
]

import { mapActions } from 'vuex'
export default {
  name: 'sect_manager',
  components: {
    perButton,
    preview,
  },
  props: {
    sect_data: {
      type: Object,
      default: () => ({}),
    },
    view_type: {
      type: String,
    },
  },
  computed: {
    isEdit: function() {
      if (this.view_type == 'edit') {
        return true
      }

      return false
    },
  },
  data() {
    return {
      activity_data_preview: {},
      options: cityList,
      valueCascader: [],
      dataForm: {
        _id: '',
        id: '',
        name: '',
        avatar: '',
        declaration: '',
        city: '',
        desc: '',
        cityDetail: {}, //桌游吧所在地区
        board_game_bar: {
          has: true,
          name: '',
          link: '',
          address: '', //桌游吧详细地址
          referee_id: '', //裁判游戏编号
          journalist_id: '', //记者游戏编号
          anchor_sign: '', //主播是否签约
          anchor_id: '', //主播游戏编号
          anchor_platform: '', //主播所在平台
          desc: '',
          gis: {
            addr: '',
            lat: '',
            lng: '',
          },
        },
        members: [],
      },
      membersParams: {
        name: '',
        avatar: '',
        uid: '',
        job: '',
        desc: '',
      },
      membersRow: {},
      membersVisible: false,
      membersImageValid: false,
      membersAction: 0,
      membersRules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: ['blur', 'change'],
          },
          {
            min: 1,
            max: 10,
            message: '长度在 1 到 10 个字符',
            trigger: ['blur', 'change'],
          },
        ],
        avatar: [{ required: true, message: '请选择头像', trigger: 'change' }],
        uid: [
          { required: true, message: '请输入UID', trigger: ['blur', 'change'] },
          {
            min: 8,
            max: 8,
            message: '长度8个字符',
            trigger: ['blur', 'change'],
          },
          { validator: this.idValidator, trigger: ['blur', 'change'] },
        ],
        job: [
          {
            required: true,
            message: '请输入职务',
            trigger: ['blur', 'change'],
          },
          {
            min: 1,
            max: 4,
            message: '长度在 1 到 4 个字符',
            trigger: ['blur', 'change'],
          },
        ],
        desc: [
          {
            required: true,
            message: '请输入简介',
            trigger: ['blur', 'change'],
          },
          {
            min: 1,
            max: 60,
            message: '长度在 1 到 60 个字符',
            trigger: ['blur', 'change'],
          },
        ],
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入门派名称',
            trigger: ['blur', 'change'],
          },
          {
            min: 1,
            max: 10,
            message: '长度在 1 到 10 个字符',
            trigger: ['blur', 'change'],
          },
        ],
        avatar: [
          { required: true, message: '请选择门派头像', trigger: 'change' },
        ],
        declaration: [
          {
            required: false,
            message: '请输入宣言',
            trigger: ['blur', 'change'],
          },
          {
            min: 0,
            max: 201,
            message: '长度在 1 到 200 个字符',
            trigger: ['blur', 'change'],
          },
        ],
        city: [
          {
            required: false,
            message: '请输入城市',
            trigger: ['blur', 'change'],
          },
          {
            min: 1,
            max: 10,
            message: '长度在 1 到 10 个字符',
            trigger: ['blur', 'change'],
          },
        ],
        desc: [
          {
            required: false,
            message: '请输入介绍',
            trigger: ['blur', 'change'],
          },
        ],
        'board_game_bar.desc': [
          {
            required: true,
            message: '请输入桌游吧介绍',
            trigger: ['blur', 'change'],
          },
        ],
        members: [{ type: 'array', message: '请添加成员', trigger: 'change' }],
      },
      editorOption: {
        placeholder: '请填写门派介绍',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector('#quillEditorQiniu .avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector('#quillEditorQiniu .avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
      editorOption1: {
        placeholder: '请填写桌游吧介绍',
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === 'custom-size') {
                  value = prompt('请输入字体大小')
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      '请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备'
                    )
                    value = ''
                  }
                }

                this.quill.format('size', value)
              },
              color: function(value) {
                if (value === 'custom-color') {
                  value = prompt('请输入十六进制的颜色')
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert('请输入合法的十六进制颜色')
                    value = ''
                  }
                }

                this.quill.format('color', value)
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector('#quillEditorQiniu1 .avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector('#quillEditorQiniu1 .avatar-uploader input')
                    .click()
                } else {
                  this.quill.format('video', false)
                }
              },
            },
          },
        },
      },
      previewVisible: false,
      loading: false,
    }
  },
  watch: {
    sect_data: {
      handler: function(val) {
        console.log('111')
        this.setFormData()
      },
      immediate: true,
    },
    valueCascader(newValue, oldValue) {
      console.log('用户选择的地区值：', newValue)
    },
  },
  async mounted() {
    this.setFormData()
  },
  methods: {
    findLabelByValue(data, value) {
      if (!data) return null // 添加这行来检查data是否为undefined

      for (let i = 0; i < data.length; i++) {
        if (data[i].value === value) {
          return data[i].label
        }
        if (data[i].children) {
          const label = this.findLabelByValue(data[i].children, value)
          if (label) return label
        }
      }
      return null // 如果没有找到，返回null
    },
    findValueByLabel(data, label) {
      if (!data) return null // 添加这行来检查data是否为undefined

      for (let i = 0; i < data.length; i++) {
        if (data[i].label === label) {
          return data[i].value
        }
        if (data[i].children) {
          const value = this.findValueByLabel(data[i].children, label)
          if (value) return value
        }
      }
      return null // 如果没有找到，返回null
    },
    onPreviewClosed() {
      this.previewVisible = false
    },
    idValidator(rule, value, callback) {
      if (/[^0-9,A-Z,a-z]/.test(value.trim())) {
        callback(new Error('只能包含数字和英文字母'))
      } else {
        callback()
      }
    },
    clearGis() {
      this.dataForm.board_game_bar.gis = {
        addr: '',
        lat: '',
        lng: '',
      }
    },
    onMapSelect(data) {
      this.dataForm.board_game_bar.gis.addr = data.poiaddress + data.poiname
      this.dataForm.board_game_bar.gis.lat = data.latlng.lat + ''
      this.dataForm.board_game_bar.gis.lng = data.latlng.lng + ''
    },
    setFormData() {
      let data = this.sect_data
      if (data && data._id) {
        Object.keys(this.dataForm).forEach((key) => {
          if (data[key]) {
            this.dataForm[key] = data[key]
          }
        })

        if (
          data &&
          data.board_game_bar &&
          data.board_game_bar.has &&
          data.board_game_bar.desc
        ) {
          this.dataForm.board_game_bar.has = data.board_game_bar.has
          this.dataForm.board_game_bar.desc = data.board_game_bar.desc
          if (
            data.board_game_bar.gis &&
            data.board_game_bar.gis.addr &&
            data.board_game_bar.gis.lat &&
            data.board_game_bar.gis.lng
          ) {
            this.dataForm.board_game_bar.gis = {
              addr: data.board_game_bar.gis.addr,
              lat: data.board_game_bar.gis.lat,
              lng: data.board_game_bar.gis.lng,
            }
          }
        }

        if (data.members) {
          this.dataForm.members = data.members.map((r) => {
            return {
              name: r.name,
              avatar: r.avatar,
              uid: r.uid,
              job: r.job,
              desc: r.desc,
            }
          })
        }
        if (data.board_game_bar.anchor_sign === 1) {
          this.dataForm.board_game_bar.anchor_sign = true
        } else {
          this.dataForm.board_game_bar.anchor_sign = data.board_game_bar.anchor_sign
        }
        if (data.cityDetail) {
          const a = this.findValueByLabel(
            this.options,
            data.cityDetail.province
          )
          const b = this.findValueByLabel(this.options, data.cityDetail.city)
          const c = this.findValueByLabel(this.options, data.cityDetail.area)
          console.log('🚀 ~ setFormData ~  this.valueCascader:', a, b, c)
          // this.valueCascader[0] = a
          // this.valueCascader[1] = b
          // this.valueCascader[2] = c

          this.valueCascader = [a, b, c]
          console.log("🚀 ~ setFormData ~ this.valueCascader:", this.valueCascader)
        }
      } else {
        this.dataForm = {
          _id: '',
          id: '',
          name: '',
          avatar: '',
          declaration: '',
          city: '',
          desc: '',
          cityDetail: {}, //桌游吧所在地区
          board_game_bar: {
            has: true,
            name: '',
            link: '',
            address: '', //桌游吧详细地址
            referee_id: '', //裁判游戏编号
            journalist_id: '', //记者游戏编号
            anchor_sign: '', //主播是否签约
            anchor_id: '', //主播游戏编号
            anchor_platform: '', //主播所在平台
            desc: '',
            gis: {
              addr: '',
              lat: '',
              lng: '',
            },
          },
          members: [],
        }
      }
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData()

          console.log('🚀 ~ this.$refs.dataForm.validate ~ d:', d)

          let { data, errorCode } = await this.$http.armory.save('sect', d)
          if (errorCode != '0000') {
            this.$message.error('保存失败')
            return
          }

          this.$message({
            type: 'success',
            message: '保存成功',
          })

          this.removeTabByPath('/armory/sect/add')
          this.removeTabByPath('/armory/sect/edit')
          this.$router.push('/armory/sect/index')
          this.$bus.$emit('onSectChanged', data)
        } else {
          console.log('error submit!!')
          console.log(invalidFields)
          let fileds = Object.keys(invalidFields)
          if (fileds.length) {
            let id1 = fileds[0]
            if (id1 == 'desc') {
              id1 = 'quillEditorQiniu'
            }

            if (id1 == 'board_game_bar.desc') {
              id1 = 'quillEditorQiniu1'
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: 'smooth' })
            }

            let refKey = id1.replace(/\./g, '_')
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus()
            }
          }
          return false
        }
      })
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      }
      if (this.dataForm.board_game_bar.anchor_sign) {
        this.dataForm.board_game_bar.anchor_sign = 1
      } else {
        this.dataForm.board_game_bar.anchor_sign = 0
      }
      if (
        this.valueCascader[0] &&
        this.valueCascader[1] &&
        this.valueCascader[2]
      ) {
        this.dataForm.cityDetail.province = this.findLabelByValue(
          this.options,
          this.valueCascader?.[0]
        )
        this.dataForm.cityDetail.city = this.findLabelByValue(
          this.options,
          this.valueCascader?.[1]
        )
        this.dataForm.cityDetail.area = this.findLabelByValue(
          this.options,
          this.valueCascader?.[2]
        )
      }
      this.dataForm.board_game_bar.area = ''
      return data
    },
    
    resetForm() {
      this.$refs.dataForm.resetFields()
    },
    handleAddMember() {
      this.membersVisible = true
      this.membersAction = 1
      this.membersParams = {
        name: '',
        desc: '',
        avatar: '',
      }
      // this.$refs.membersForm.resetFields();
    },
    handleEditMember(row) {
      this.membersVisible = true
      this.membersAction = 0
      // this.$refs.membersForm.resetFields();
      this.membersRow = row
      this.membersParams = {
        name: row.name,
        avatar: row.avatar,
        uid: row.uid,
        job: row.job,
        desc: row.desc,
      }
    },
    handleDeleteMember(row) {
      let index = this.dataForm.members.indexOf(row)
      if (index >= 0) {
        this.dataForm.members.splice(index, 1)
      }
    },
    changeMember() {
      this.$refs.membersForm.validate((valid) => {
        if (valid) {
          if (this.membersAction == 0) {
            this.membersRow.name = this.membersParams.name
            this.membersRow.avatar = this.membersParams.avatar
            this.membersRow.uid = this.membersParams.uid
            this.membersRow.job = this.membersParams.job
            this.membersRow.desc = this.membersParams.desc
          } else {
            this.dataForm.members.push({
              name: this.membersParams.name,
              avatar: this.membersParams.avatar,
              uid: this.membersParams.uid,
              job: this.membersParams.job,
              desc: this.membersParams.desc,
            })
          }
          this.membersVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancelDialog() {
      this.membersVisible = false
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }

      return isJPG && isLt2M
    },
    async httpUploadRequest(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      console.log("🚀 ~ httpUploadRequest ~ result:", result)
      if (result) {
        this.dataForm.avatar = result
      } else {
        this.$message.error('上传失败')
      }
      this.loading = false
    },
    async beforeMemberImageUpload(file) {
      this.membersImageValid = false
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }

      // let self = this;
      // let width = 300;
      // let height = 300;
      // let sizeRes = await new Promise(function (resolve, reject) {
      //   let filereader = new FileReader();
      //   filereader.onload = e => {
      //     let src = e.target.result;
      //     const image = new Image();
      //     image.onload = function () {
      //       if (this.width != width || (height && this.height != height)) {
      //         self.$message.error('请上传宽为' + width + "px，高为" + height + "px的图片");
      //         resolve(false);
      //       } else {
      //         resolve(true);
      //       }
      //     };
      //     image.onerror = reject;
      //     image.src = src;
      //   };
      //   filereader.readAsDataURL(file);
      // });
      //
      // let res = isJPG && isLt2M && sizeRes;
      let res = isJPG && isLt2M
      this.membersImageValid = true
      return res
    },
    async httpUploadMemberRequest(item) {
      if (!this.membersImageValid) {
        return
      }

      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        this.membersParams.avatar = result
      } else {
        this.$message.error('上传失败')
      }
      this.loading = false
    },
    async httpUploadEditorRequest(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        console.log('上传成功')
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //取消上传动画
        this.quillUpdateImg = false
      } else {
        this.$message.error('上传失败')
      }

      this.loading = false
    },
    async httpUploadEditorRequest1(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      if (result) {
        console.log('上传成功')
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result
        let type = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
        console.log(type)
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor1.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == 'mp4' || type == 'MP4') {
          quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.insertEmbed(length, 'image', imgUrl)
        }
        // 调整光标到最后
        quill.setSelection(length + 1)
        //取消上传动画
        this.quillUpdateImg = false
      } else {
        this.$message.error('上传失败')
      }

      this.loading = false
    },
    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'removeTabByPath',
    ]),
  },
}
</script>

<style src="../../editor.css"></style>
<style lang="less" scoped>
.elcascader {
  width: 410px;
  height: 30px;
}
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 400px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 178px;
  height: 178px;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniu1 .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value='custom-color'] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value='custom-color']::before {
  content: '自定义颜色';
  color: black;
  width: fit-content;
}
</style>
